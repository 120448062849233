import React from 'react';
import Img from '../../assets/tc-lottery.jpg';

const Home = () => {
  return (
    <div className='lg:py-10 lg:px-40 p-5'>
      <p className='logo text-gray-800 text-2xl lg:text-3xl'>
      TC Lottery
      </p>
    <div className=' flex flex-col items-center'>
      <div className="relative w-full flex justify-center pt-5">
        <img
          src={Img}
          alt="Lucknow Game"
          className="w-full h-auto lg:w-[65vw]"
        />
      </div>
      <button className="m-5 w-[20rem] bg-blue-600 hover:bg-gray-800 text-white py-2 px-4 rounded-none w-40 transition-colors duration-300">
         <a href="https://9987up.club/#/register?invitationCode=zt2tt156352">Register On TC Lottery App</a> 
      </button>
      <table className="table-auto border-collapse border border-gray-800 w-full mb-5">
        <tbody>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Platform Name</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">TC Lottery</td>
          </tr>
          <tr>
            <td className="border border-gray-800 px-4 py-2">TC Lottery Invite Code</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">zt2tt156352</td>
          </tr>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Available Games In TC Lottery</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">Win Go, Trx Hash, 5D Lotre, K3 Lotre</td>
          </tr>
          <tr>
            <td className="border border-gray-800 px-4 py-2">Recharge Bonus</td>
            <td className="border border-gray-800 px-4 py-2 font-semibold">2% Cashback On USDT Recharge</td>
          </tr>
        </tbody>
      </table>
    </div>
      <p className='py-2'>
        Customer Support – <a href="https://9987up.club/#/register?invitationCode=zt2tt156352" className="text-blue-500 hover:underline">https://9987up.club/#/register?invitationCode=zt2tt156352</a>
      </p>
      <p className='py-2'>
      TC Lottery Channel – <a href="https://t.me/earnwithnavin" className="text-blue-500 hover:underline">https://t.me/earnwithnavin</a>
      </p>

      <p className='py-2'>
      TC Lottery is a well-known colour prediction website with a huge user base. There has been a tremendous demand for colour prediction games in India lately. That’s why I will introduce you to TC Lottery today.

On TC Lottery app, you can play games like Win Go, Trx hash, 5D, and more amazing games. More importantly, it is a game of probability, you can win big money but can also lose money.

In the Wingo Game, you have to place your money in a colour like green or red. If your prediction is right then you can double your money. These are the roulette strategies which could be applied to the Wingo game as well. Check the Strategies.
      </p>

    </div>
  )
}

export default Home;
