import React from "react";

const ContactUs = () => {
  return (
    <div className="lg:py-10 lg:px-40 p-5">
      <div className="max-w-screen-xl mx-auto text-left">
        <p className="logo text-gray-800 text-2xl lg:text-3xl py-5">
          Contact Us
        </p>
        <p className="text-gray-800">
          For any Promotion related query you can mail at: &nbsp;
          <a
            href="http://tclottery-vip.in/"
            className="text-blue-500 hover:underline"
          >
            http://tclottery-vip.in/
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
