import React from 'react';

const AboutUs = () => {
  return (
    <div className='lg:py-10 lg:px-40 p-5'>
      <div className="max-w-screen-xl mx-auto text-left">
        <p className='logo text-gray-800 text-2xl lg:text-3xl py-5'>
          About Us
        </p>
          <p className="text-gray-800">
            Our website address is: &nbsp;
            <a href="https://t.me/earnwithnavin" className="text-blue-500 hover:underline">
            https://t.me/earnwithnavin
            </a>. 
            TC Lottery is a gaming app where you can play the famous colour prediction game.
          </p>
      </div>
    </div>
  );
}

export default AboutUs;
