import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-10">
      <div className="container mx-auto px-10 lg:px-[15vw] flex flex-wrap justify-center lg:justify-between items-center">
        <p className="text-center mb-4 sm:mb-0 sm:mr-4 text-gray-800">
          Copyright © 2024 TC Lottery
        </p>
        <div className="flex flex-wrap justify-center items-center text-blue-500">
          <Link to="/about" className="mx-2 hover:underline">About Us</Link>
          <Link to="/contact" className="mx-2 hover:underline">Contact Us</Link>
          <Link to="/privacy-policy" className="mx-2 hover:underline">Privacy Policy</Link>
          <Link to="/disclaimer" className="mx-2 hover:underline">Disclaimer</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
