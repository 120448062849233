import React, { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to close menu when a navigation link is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className='relative pt-2'>
      <div className="flex flex-col lg:flex-row lg:space-x-20 space-y-4 lg:space-y-0 items-center justify-center w-full lg:w-auto pb-5">
        <button className="bg-orange-500 text-white py-2 px-4 rounded-none w-[10rem]"><a href="https://9987up.club/#/register?invitationCode=zt2tt156352">Login</a></button>
        <button className="bg-orange-500 text-white py-2 px-4 rounded-none w-[10rem]"><a href="https://9987up.club/#/register?invitationCode=zt2tt156352">Register</a></button>
      </div>

      <div className='px-5 z-50 py-[0.8rem] lg:px-40 flex justify-between items-center'>
        <div className='lg:mr-10 cursor-pointer flex items-center space-x-4'>
          <p className='logo font-semibold text-black text-2xl lg:text-4xl'>
          TC Lottery
          </p>
        </div>
        <div className='flex items-center space-x-2 lg:space-x-10'>
          <div className='hidden lg:flex space-x-4'>
            {/* Use Link component for navigation */}
            <Link to="/" className='text-blue-500' onClick={closeMenu}>Home</Link>
            <Link to="/about" className='text-blue-500' onClick={closeMenu}>About us</Link>
            <Link to="/contact" className='text-blue-500' onClick={closeMenu}>Contact us</Link>
          </div>
          <div className="lg:hidden">
            <Hamburger toggled={isOpen} size={20} toggle={toggleMenu} />
          </div>
        </div>
        
      </div>
      <Divider/>
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            initial={{ height: 0, opacity: 0 }} 
            animate={{ height: "auto", opacity: 1 }} 
            exit={{ height: 0, opacity: 0 }} 
            transition={{ duration: 0.5 }}
            className="w-full bg-gray-100 flex flex-col items-start p-5 space-y-4"
          >
            {/* Use Link component for navigation */}
            <Link to="/about" className='text-lg cursor-pointer' onClick={closeMenu}>About us</Link>
            <Link to="/contact" className='text-lg cursor-pointer' onClick={closeMenu}>Contact us</Link>
            <Link to="/disclaimer" className='text-lg cursor-pointer' onClick={closeMenu}>Disclaimer</Link>
            <Link to="/privacy-policy" className='text-lg cursor-pointer' onClick={closeMenu}>Privacy Policy</Link>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
